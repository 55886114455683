import React, { useState, useEffect } from "react";
import Select from "react-select";
import Papa from "papaparse";
import FlipCard from "./FlipCard";

const App = () => {
    const [lessons, setLessons] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [words, setWords] = useState([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [totalGuesses, setTotalGuesses] = useState(0); // Total correct guesses
    const [wordGuessCounts, setWordGuessCounts] = useState({}); // Track correct guesses for each word

    useEffect(() => {
        const lessonCount = 37;
        const options = Array.from({ length: lessonCount }, (_, i) => ({
            value: i + 1,
            label: `Lecke ${i + 1}`,
        }));
        setLessons(options);
        // Load total correct guesses and word guess counts from local storage
        const storedTotalGuesses = localStorage.getItem("totalGuesses");
        const storedWordGuessCounts = localStorage.getItem("wordGuessCounts");
        setTotalGuesses(
            storedTotalGuesses ? parseInt(storedTotalGuesses, 10) : 0
        );
        setWordGuessCounts(
            storedWordGuessCounts ? JSON.parse(storedWordGuessCounts) : {}
        );
    }, []);

    const handleLessonChange = (selectedOption) => {
        setSelectedLesson(selectedOption);
        const lessonFile = `/lessons/lecke_${selectedOption.value}.csv`;
        Papa.parse(lessonFile, {
            download: true,
            header: true,
            complete: (result) => {
                const updatedWords = result.data.map((item, index) => ({
                    id: `${selectedOption.value}-${index + 1}`,
                    hungarian: item.hungarian,
                    serbian: item.serbian,
                }));
                setWords(shuffleArray(updatedWords));
                setCurrentWordIndex(0);
            },
        });
    };

    const handleCorrectGuess = (wordId) => {
        const newTotalGuesses = totalGuesses + 1;
        const newWordGuessCounts = {
            ...wordGuessCounts,
            [wordId]: (wordGuessCounts[wordId] || 0) + 1,
        };
        setTotalGuesses(newTotalGuesses);
        setWordGuessCounts(newWordGuessCounts);
        localStorage.setItem("totalGuesses", newTotalGuesses.toString());
        localStorage.setItem(
            "wordGuessCounts",
            JSON.stringify(newWordGuessCounts)
        );
    };

    const calculateLearnedPercentage = () => {
        const totalNeededGuesses = words.length * 10;
        return ((totalGuesses / totalNeededGuesses) * 100).toFixed(2);
    };

    const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

    const getRandomOptions = () => {
        const currentWord = words[currentWordIndex];
        let options = words
            .filter((word) => word.id !== currentWord.id)
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
            .map((word) => word.serbian);
        options.push(currentWord.serbian);
        return shuffleArray(options);
    };

    const handleNextWord = () => {
        setCurrentWordIndex((prev) => (prev + 1) % words.length);
    };

    const handlePreviousWord = () => {
        setCurrentWordIndex((prev) => (prev - 1 + words.length) % words.length);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
            <h1 className="text-3xl font-bold text-blue-600 mb-6">
                Flipcards Learning App
            </h1>
            <div className="w-full max-w-md">
                <Select
                    options={lessons}
                    value={selectedLesson}
                    onChange={handleLessonChange}
                    placeholder="Select a Lesson"
                    className="mb-6"
                />
            </div>
            {words.length > 0 && (
                <FlipCard
                    hungarian={words[currentWordIndex].hungarian}
                    serbian={words[currentWordIndex].serbian}
                    options={getRandomOptions()}
                    onNext={handleNextWord}
                    onPrevious={handlePreviousWord}
                    onCorrectGuess={() =>
                        handleCorrectGuess(words[currentWordIndex].id)
                    }
                    guessCount={
                        wordGuessCounts[words[currentWordIndex].id] || 0
                    }
                />
            )}
            <div className="mt-4">
                <p>Learned: {calculateLearnedPercentage()}%</p>
            </div>
        </div>
    );
};

export default App;
