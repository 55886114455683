import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

const FlipCard = ({
    hungarian,
    serbian,
    options,
    onNext,
    onCorrectGuess,
    guessCount,
}) => {
    const [flipped, setFlipped] = useState(false);

    const handleOptionClick = (option) => {
        if (option === serbian) {
            onCorrectGuess();
        }
        onNext();
    };

    return (
        <div className="flex flex-col items-center justify-center space-y-6 mt-6">
            <div>Broj pogodaka trenutne reci: {guessCount}</div>
            <div
                onClick={() => setFlipped(!flipped)}
                className="relative w-80 h-48 bg-white rounded-lg shadow-lg cursor-pointer flex items-center justify-center text-xl font-semibold text-gray-800"
            >
                <div
                    className={`absolute inset-0 flex items-center justify-center transition-transform transform ${
                        flipped ? "rotate-y-180" : ""
                    }`}
                    style={{
                        transformStyle: "preserve-3d",
                        backfaceVisibility: "hidden",
                    }}
                >
                    {flipped ? serbian : hungarian}
                </div>
            </div>
            {!flipped &&
                options.map((option) => (
                    <button
                        key={option}
                        onClick={() => handleOptionClick(option)}
                        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition"
                    >
                        {option}
                    </button>
                ))}
        </div>
    );
};

export default FlipCard;
